@media screen and (min-width: 992px) {
  .cardBox {
    display: grid;
    grid-gap: 10px;
    /* grid-template-columns: repeat(auto-fit,minmax(345px,1fr)); */
    grid-template-columns: auto auto auto;
    position: relative;
    transform: none;
    transform-origin: 50% 50% 0px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .cardBox {
    display: grid;
    grid-gap: 10px;
    /* grid-template-columns: repeat(auto-fit,minmax(345px,1fr)); */
    grid-template-columns: auto auto;
    position: relative;
    transform: none;
    transform-origin: 50% 50% 0px;
  }
}

@media only screen and (max-width: 767px) {
  .cardBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.portfolio {
  font-size: 48px;
  color: white;
}

.cta {
  background: transparent;
  border-radius: 8px;
  color: white;

  /* border-style: solid ;
    border-color: yellow; */
  border: 1px solid #64572e;
  padding: 8px 20px;
}

.cta:hover {

    color: #f2f0c0;


}


.ctaContainer {
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
    max-width: 450px;
}
