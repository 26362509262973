


.aboutContainer{

    position: relative;
    border-radius: 25px;
    border-left: 2px solid #64572e;
    border-top: 2px solid #64572e;
    background: linear-gradient(136.48deg,#5a5b56 -29.08%, transparent 35.75%)


}

.title{
    color: white;
    font-size: 48px
}

.text {
    color: #82837d;
    font-size: 18px;
    line-height: 150%;
    /* background: linear-gradient(-45deg, #000000 0%, #64572e 100%); */
    


}

.cloud {
    width: 1200px;
}


.list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 40px auto;
    max-width: 250px;
    list-style: none;
}


.list li {
    padding: 5px;
    color: #f2f0c0;
    text-shadow: 2px 2px 4px #f2f0c0
}


