
.box {

    
    width: 400px;
    height: 485px;
    border-style: solid;
  
    text-align: center;
    border: 3px solid #64572e;
    border-radius: 15px; 
    position: relative;

    
}

.topsymbolContainer {
    position: absolute;
    left: 25px;
    top: 15px;
    font-size: 25px;
    color: white;
}

.topbuffer {
    margin-top: -18px;
}

.bottombuffer {

    margin-bottom: -2px
}

.bottomsymbolContainer {
    position: absolute;
    right: 25px;
    bottom: 4px;
    font-size: 25px;
    color: white;
    transform: rotate(180deg)
}


.buffer {
    margin-top: 176px;
}

.cardTitle {
    color: white;
}


.aceContainer {

    display: flex;
    justify-content: center;

}





.pic {
    max-width: 550px;
    max-height: 485px;
}

.t {
    width: 100%;
    height: 100%;
}

.icon  a{
    color: white;
    font-size: 32px;
}


.icon a:hover {
    color: #f2f0c0;
}