/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */



.tag-cloud {
  position: relative;
  width: 400px;
  height: 400px;
  perspective: 1000px;
}

.tag {
  position: absolute;
  display: inline-block;
  background-color: #f0f0f0;
  padding: 5px;
  border-radius: 4px;
  transform-origin: 50% 50%;
  animation: rotateTag 10s infinite linear;
}

@keyframes rotateTag {
  0% {
    transform: rotateX(0) rotateY(0) rotateZ(0);
  }
  100% {
    transform: rotateX(1turn) rotateY(1turn) rotateZ(1turn);
  }
}
