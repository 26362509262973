

.cardContainer {
    width: 345px;
    height: 390px;
    border-radius: 10px;
    text-align: center;
    padding-top: 150px;
    transition: padding 1s;
    background: linear-gradient(0.46deg,#595b56 -222.72%,#131311 79.16%);
    position: relative;
}



.desc{
    color: transparent;
    transition: color 1s;
}

.cardTitle {
    color: white;
}

.ctaCard{
    position: absolute;
    bottom: 0

}


.cardContainer:hover {
    padding-top: 70px;
}

.cardContainer:hover .desc{
    color:  #82837D
}


.cardContainer:hover .links a{
    color:  #82837D
}

img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }

  .links a{

    
    color: transparent;
    text-decoration: none;
     
  }

  .links a:hover{

    
    color: white !important;
    
     
  }