


.text-sphere{

    position: relative;
    top: 0;
    width: 100%;
    height: 100vhl;
    display: flex;
    justify-content: center;
    align-items: center;
}


.tagcloud {
    display: inline-block;
    top: 0;
    left: 0;
    

}


.tagcloud--item {
    color: #F2F0C0;
    
}

.tagcloud--item:hover {
    color: #fff;
    
}