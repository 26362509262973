
.test {


    width : 400px;
    height : '50%';
}


img {
    width : 400px
}