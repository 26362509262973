

.nav {

    /* background-color: blue; */
    height: 30px;

    display: flex;
    justify-content: space-between;
    color: white;
    margin-top: 10px;
}

.nav div span a{
    text-decoration: none;
    color: white;
}

.nav div span a:hover {
    color: #f2f0c0;
}


.about {
    margin-right: 20px;
}




.email{
    
}